import "animate.css";
import BookCards from "./components/bookCards";
import BooksGallery from "./components/booksGellary";
import Header from "./components/header";
import MainForm from "./components/mainForm";
import NavBar from "./components/navBar";
import SocialMedia from "./components/socialMedia";
import SubHeader from "./components/subHeader";
import { useEffect, useState } from "react";
import Api from "./api/api";
import Loading from "./components/loading";

function App() {
  const [showBookCards, setShowBookCards] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [bookCardsAnimating, setBookCardAnimating] = useState(false);
  const [grade, setGrade] = useState(null);
  const handleShowBookCards = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setBookCardAnimating(true);
    }, 500);
    setTimeout(() => {
      setShowBookCards(true);
    }, 1000); // Adjust delay as needed
  };
  const onGradeChange = (value) => {
    setGrade(value)
  };
  return (
    <div className="min-h-screen w-full" dir="rtl">
      <div className="relative w-full  main-clip-path ">
        <div className="absolute top-0 left-0 w-full h-full bg-main bg-top bg-cover opacity-[0.12] blur-[1px]"></div>
        <div
          className={`w-full h-full   pb-10 ${
            !showBookCards && "md:pb-40"
          } transition-all ease-in-out duration-700`}
        >
          <NavBar />
          <Header />
          {/* <SubHeader /> */}
          {!showBookCards && (
            <MainForm
              className="hidden md:block"
              onHide={handleShowBookCards}
              isAnimating={isAnimating}
              onGradeChange={onGradeChange}
            />
          )}
        </div>
      </div>
      <div>
        {!showBookCards && (
          <MainForm
            className="block md:hidden"
            onHide={handleShowBookCards}
            isAnimating={isAnimating}
            onGradeChange={onGradeChange}
          />
        )}
        {!showBookCards && (
          <BooksGallery
            className="relative md:-top-32"
            isAnimating={isAnimating}
          />
        )}
        {(showBookCards || bookCardsAnimating) && <BookCards grade={grade} />}
      </div>
      <SocialMedia />
    </div>
  );
}

export default App;
