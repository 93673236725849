import Lottie from "react-lottie";
import lottieJson from "../../assets/images/bookidea.json";
import ElmoaaserIcon from "../../assets/icons/elmoaaser";

const Loading = ({size,color}) => {
  return (
    <div
      className={`flex h-full items-center justify-center mx-auto ${
        size === "sm" ? "h-8 w-8" : size === "md" ? "h-12 w-12" : "h-16 w-16"
      } `}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={`animate-spin ${color ? "color" : "text-primary"} `}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
    </div>
  );
};

export default Loading;
