import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../api/api";
import Loading from "../loading";

const MainForm = ({ className, onHide, isAnimating,onGradeChange }) => {
  const [grades, setGrades] = useState([
    {id:1,g_name:" الإبتدائية"},
    {id:2,g_name:" الإعدادية"},
    {id:3,g_name:" الثانوية"},
  ]);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    teahcer_name: "",
    teacher_phone: "",
    grade_id: "",
  });

  const handleChange = (event) => {
    if(event.target.name==="teacher_phone"){
      if(!/^[0-9]*$/.test(event.target.value) || event.target.value.length >11)
      {
        return false
      }
    }
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if(event.target.name==="grade_id")
    {
      console.log(onGradeChange)
      onGradeChange(event.target.value)
    }
  };

  const siteKey = process.env.REACT_APP_SITE_KEY;
  const recaptchaRef = useRef(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [captcha, setCaptcha] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // teacher_phone number validation
    if (
      !/^(011|012|010|015)\d{8}$/.test(formData.teacher_phone) ||
      formData.teahcer_name.length <= 3 ||
      !formData.grade_id
    ) {
      toast.error("الرجاء التأكد من صحة جميع البيانات المدخلة");
    } else {
      Api.addTeacher({ ...formData })
        .then((res) => {
          if (res.status) {
            onHide();
          }
        })
        .catch(() => []);
    }

    // window.grecaptcha.execute();
  };

  // useEffect(() => {
  //   setLoading(true);
  //   Api.getGrades()
  //     .then((res) => {
  //       setLoading(false);
  //       // console.log(res.data)
  //       setGrades(res.data);
  //     })
  //     .catch(() => {});
  // }, []);

  const submitCaptcha = (token) => {
    console.log(token);
  };

  return (
    <div
      className={`container px-5 py-5 ${className} animate__animated ${
        isAnimating ? "animate__fadeOutUp" : "animate__fadeInDown"
      }`}
    >
      <form
        className="bg-none md:bg-white flex flex-col md:flex-row rounded-xl gap-y-3 md:gap-y-0"
        onSubmit={handleSubmit}
      >
        <div className="grow-1 w-full grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-x-1   rounded-xl md:rounded-none md:rounded-ss-xl md:rounded-es-xl divide-x-2 divide-text-dark-2">
          <input
            type="text"
            placeholder="اسم المعلم"
            className="grow-1 py-3 px-2 rounded-xl md:rounded-none md:rounded-ss-xl md:rounded-es-xl border border-text-dark-1 md:border-none  focus:outline-primary "
            name="teahcer_name"
            value={formData.teahcer_name}
            onChange={handleChange}
          />
          <input
            type="tel" pattern="[0-9]*" 
            placeholder="رقم الهاتف"
            dir="rtl"
            className="grow-1 py-3 px-2  rounded-xl md:rounded-none border border-text-dark-1 md:border-none outline-text-dark-1 md:outline-none focus:outline-primary"
            name="teacher_phone"
            value={formData.teacher_phone}
            onChange={handleChange}
          />
          {loading ? (
            <div><Loading size="sm"/></div>
          ) : (
            <select
              className="grow-1 py-3 px-2 text-text-dark-1 bg-white rounded-xl md:rounded-none border border-text-dark-1 md:border-none  focus:outline-primary focus:border-primary focus:ring-primary form-select appearance-auto"
              name="grade_id"
              value={formData.grade_id}
              onChange={handleChange}
            >
              <option value="" className="" disabled>
                المرحلة الدراسية
              </option>
              {grades?.map((grade, index) => (
                <option key={grade.id} value={grade.id}>
                  {grade.g_name}
                </option>
              ))}
            </select>
          )}
        </div>
        {/* <div
          id="recaptcha"
          className="g-recaptcha"
          data-size="invisible"
          data-sitekey={siteKey}
          data-callback={submitCaptcha}
          ref={recaptchaRef}
        /> */}
        <button
          className=" grow-0 px-5 py-3 md:py-0 bg-primary text-white rounded-xl md:rounded-none md:rounded-ee-xl md:rounded-se-xl"
          type="submit"
        >
          إشتراك
        </button>
      </form>
      {/* <script
        src={`https://www.google.com/recaptcha/api.js?render=${siteKey}&async=defer`}
      /> */}
    </div>
  );
};

export default MainForm;
