import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";

import book1 from "../../assets/images/books/book-1.png";
import book2 from "../../assets/images/books/book-2.png";
import book3 from "../../assets/images/books/book-3.png";
import book4 from "../../assets/images/books/book-4.png";
import book5 from "../../assets/images/books/book-5.png";
import book6 from "../../assets/images/books/book-6.png";
import book7 from "../../assets/images/books/book-7.png";
import book8 from "../../assets/images/books/book-8.png";
import book9 from "../../assets/images/books/book-9.png";

const BooksGallery = ({ className, isAnimating }) => {
  const booksList = [
    { title: "book 9", src: book9 },
    { title: "book 8", src: book8 },
    { title: "book 7", src: book7 },
    { title: "book 5", src: book5 },
    { title: "book 6", src: book6 },
    { title: "book 4", src: book4 },
    { title: "book 1", src: book1 },
    { title: "book 2", src: book2 },
    { title: "book 3", src: book3 },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <>
      <div className={`container ${className}`}>
        <div className="hidden md:flex flex-row-reverse justify-between items-end ">
          {booksList.map((book, index) => (
            <img
              src={book.src}
              alt={book.title}
              className={`shadow-2xl hover:!w-[20%] hover:!scale-105 transition-all ease-in-out hover:!z-[1000] duration-300 animate__animated  ${
                isAnimating ? "animate__fadeOutDown" : "animate__fadeInUp"
              } `}
              style={{
                width: `${20 - index}%`,
                
                marginLeft: `-${index !== 0 ? 11 : 0}%`,
                zIndex: `${99 - index}`,
                animationDelay: `${index / 20}s`,
              }}
            />
          ))}
        </div>
        <div
          className={`block md:hidden w-full px-5 animate__animated  ${
            isAnimating ? "animate__fadeOutDown " : "animate__fadeInUp"
          } `}
          dir="ltr"
        >
          <Swiper
            className=""
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            // Add additional Swiper options as needed (e.g., navigation, pagination)
          >
            {booksList.map((book) => (
              <SwiperSlide key={book.title}>
                <img src={book.src} alt={book.title} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default BooksGallery;
