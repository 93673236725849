import BookCardItem from "../bookCardItem";
import book1 from "../../assets/images/books/book-1.png";
import book2 from "../../assets/images/books/book-2.png";
import book3 from "../../assets/images/books/book-3.png";
import book4 from "../../assets/images/books/book-4.png";
import book5 from "../../assets/images/books/book-5.png";
import book6 from "../../assets/images/books/book-6.png";
import book7 from "../../assets/images/books/book-7.png";
import book8 from "../../assets/images/books/book-8.png";
import book9 from "../../assets/images/books/book-9.png";

const BookCards = ({ grade }) => {
  const booksList = [
    {
      title: "Connect",
      src: book9,
      subTitle: "Your Guide to Creative and Effective Teaching",
      link: "https://gps-eg.org/uj2ty01y",
      color: "#235A95",
      level: "4th primary",
      gradeId: 1,
    },
    {
      title: "Connect plus",
      src: book8,
      subTitle: "Your Guide to Creative and Effective Teaching",
      link: "https://gps-eg.org/aGpXaDus",
      color: "#ED4999",
      level: "4th primary",
      gradeId: 1,
    },
    {
      title: "Connect ",
      src: book7,
      subTitle: "Your Guide to Creative and Effective Teaching",
      link: "https://gps-eg.org/lQPnFxzO",

      color: "#99B612",
      level: "5th primary",
      gradeId: 1,
    },
    {
      title: "Connect plus",
      src: book5,
      subTitle: "Your Guide to Creative and Effective Teaching",
      link: "https://gps-eg.org/ilgcY0QM",
      color: "#F85294",
      level: "5th primary",
      gradeId: 1,
    },
    {
      title: "Connect",
      src: book6,
      subTitle: "Your Guide to Creative and Effective Teaching",
      link: "https://gps-eg.org/BPiWUWOO",
      color: "#1B553D",
      level: "6th primary",
      gradeId: 1,
    },
    {
      title: " Connect plus",
      src: book4,
      subTitle: "Your Guide to Creative and Effective Teaching",
      link: "https://gps-eg.org/MwyBn7mu",
      color: "#54589C",
      level: "6th primary",
      gradeId: 1,
    },

    {
      title: "Get Ready",
      src: book1,
      subTitle: "Your Guide to Creative and Effective Teaching",
      link: "https://gps-eg.org/BQwJDZfe",
      color: "#00ACEC",
      level: "1st Prep",
      gradeId: 2,
    },
    {
      title: "New Hello",
      src: book2,
      subTitle: "Your Guide to Creative and Effective Teaching",
      link: "https://gps-eg.org/4kH6gcdz",
      color: "#00BAD2",
      level: "1st Sec",
      gradeId: 3,
    },
    {
      title: "New Hello",
      src: book3,
      subTitle: "Your Guide to Creative and Effective Teaching",
      link: "https://gps-eg.org/wwFYUmSa",
      color: "#58BA49",
      level: "2nd Sec",
      gradeId: 3,
    },
  ];
  return (
    <div
      className="container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 px-3 md:px-1 pt-3 pb-7 animate__animated animate__fadeInUp"
      dir="ltr"
    >
      {booksList
        .filter((b) => b.gradeId == grade)
        .map((book, index) => (
          <BookCardItem book={book} />
        ))}
    </div>
  );
};

export default BookCards;
