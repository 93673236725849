import Elmemt7anIcon from "../../assets/icons/elamt7an";
import ElmoaaserIcon from "../../assets/icons/elmoaaser";
import Logo from "../../assets/icons/logo.svg";
const NavBar = () => {
  return (
    <div className="container   flex flex-row  content-center items-center justify-center md:justify-between animate__animated animate__fadeInDown">
      <a className="max-w-[150px] py-4 " href="/">
        <ElmoaaserIcon />
      </a>
    </div>
  );
};

export default NavBar;
